import {locales} from 'src/config';

export const InvoiceDetail = ({invoice}) => {

  const formatNumber = (value: any, options?: Intl.NumberFormatOptions) => {
    if (value) {
      return Intl.NumberFormat(locales, options).format(value);
    }
    return value;
  }

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card p-fluid">
          <h5 className="primary-text uppercase">Thông tin hóa đơn</h5>

          <div className="field mb-2">
            <label htmlFor="name1" className="mb-0">Cửa hàng</label>
            <div className="p-inputtext border-0">
              <b>{invoice?.storeName}</b>
            </div>
          </div>
          <div className="field mb-2">
            <label htmlFor="orid" className="mb-0">Mã hóa đơn</label>
            <div className="p-inputtext border-0">
              <b>{invoice?.orderId}</b>
            </div>
          </div>
          <div className="field mb-2">
            <label htmlFor="orid" className="mb-0">Ngày hóa đơn</label>
            <div className="p-inputtext border-0">
              <b>{invoice?.orderDate}</b>
            </div>
          </div>
          <div className="field mb-2">
            <label htmlFor="orid" className="mb-0">Thành tiền bán</label>
            <div className="p-inputtext border-0">
              <b>{formatNumber(invoice?.grandTotal)} VND</b>
            </div>
          </div>

          {invoice?.VAT10 && <div className="field mb-2">
						<label htmlFor="orid" className="mb-0">VAT 10%</label>
						<div className="p-inputtext border-0">
							<b>{formatNumber(invoice?.VAT10)} VND</b>
						</div>
					</div>}
          {invoice?.VAT8 && <div className="field mb-2">
						<label htmlFor="orid" className="mb-0">VAT 8%</label>
						<div className="p-inputtext border-0">
							<b>{formatNumber(invoice?.VAT8)} VND</b>
						</div>
					</div>}
          {invoice?.VAT5 && <div className="field mb-2">
						<label htmlFor="orid" className="mb-0">VAT 5%</label>
						<div className="p-inputtext border-0">
							<b>{formatNumber(invoice?.VAT5)} VND</b>
						</div>
					</div>}

          <p><i>Lưu ý:</i></p>
          <p className="mb-2">- Mỗi bill thanh toán chỉ xuất được một hóa đơn điện tử.</p>
          <p className="mb-2">- Khách hàng chịu trách nhiệm về thông tin xuất hóa đơn của mình.</p>
          <p className="mb-2">- Hóa đơn điện tử chỉ được xuất trong ngày và xuất trước 22h00.</p>
        </div>
      </div>
    </div>
  );
}