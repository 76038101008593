// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  font-size: 14px;
}

.primary-text {
  color: #0275b5;
}

.layout-topbar .layout-topbar-logo img {
  height: auto !important;
}

.layout-topbar-logo-right {
  order: 3;
}

label .required {
  color: red;
  margin-left: 5px;
}

.p-fluid .un-fluid .p-button {
  width: unset;
}
.p-fluid .un-fluid .p-buttonset {
  display: unset;
}

@media (max-width: 991px) {
  .layout-wrapper .layout-main-container {
    padding: 6rem 1rem 1rem 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,QAAA;AACF;;AAEA;EACE,UAAA;EACA,gBAAA;AACF;;AAGE;EACE,YAAA;AAAJ;AAGE;EACE,cAAA;AADJ;;AAKA;EACE;IACE,4BAAA;EAFF;AACF","sourcesContent":["html {\n  font-size: 14px;\n}\n\n.primary-text {\n  color: #0275b5;\n}\n\n.layout-topbar .layout-topbar-logo img {\n  height: auto !important;\n}\n\n.layout-topbar-logo-right {\n  order: 3;\n}\n\nlabel .required {\n  color: red;\n  margin-left: 5px;\n}\n\n.p-fluid .un-fluid {\n  .p-button {\n    width: unset;\n  }\n\n  .p-buttonset {\n    display: unset;\n  }\n}\n\n@media (max-width: 991px) {\n  .layout-wrapper .layout-main-container {\n    padding: 6rem 1rem 1rem 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
