import {useRef, useState} from 'react';

import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Messages} from 'primereact/messages';

import axios from 'src/service/axios';

export const InvoiceRequest = ({hashString}) => {

  const msgs = useRef(null);

  const [taxInfoId, setTaxInfoId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [address, setAddress] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = event => {
    event.preventDefault();

    msgs.current.clear();

    if (['0314057952'].includes(taxInfoId)) {
      return msgs.current.show([{sticky: true, severity: 'error', detail: `Mã số thuế ${taxInfoId} không hợp lệ.`, closable: false}]);
    }

    axios({
      url: 'createEInvoice',
      method: 'post',
      data: {
        hashString,
        taxInfoId, companyName, address, customerName, phoneNumber, email
      }
    }).then(({data}) => {
      switch (data?.statusCode) {
        case '2':
          msgs.current.show([{sticky: true, severity: 'success', detail: data?.listDetails?.description, closable: false}]);
          break;
        default:
          if (data?.listDetails?.description) {
            msgs.current.show([{sticky: true, severity: 'warn', detail: data?.listDetails?.description, closable: false}]);
          } else {
            msgs.current.show([{sticky: true, severity: 'error', detail: 'Có lỗi xảy ra, quý khách vui lòng thử lại sau.', closable: false}]);
          }
      }
    }).catch(() => {
      msgs.current.show([{sticky: true, severity: 'error', detail: 'Có lỗi xảy ra, quý khách vui lòng thử lại sau.', closable: false}]);
    });
  }

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <h5 className="primary-text uppercase">Xuất hoá đơn</h5>

          <form onSubmit={handleSubmit} className="p-fluid">
            <div className="field">
              <label htmlFor="orid">Mã số thuế</label>
              <InputText value={taxInfoId} onChange={e => setTaxInfoId(e.target.value)}/>
            </div>
            <div className="field">
              <label htmlFor="orid">Tên công ty<span className="required">*</span></label>
              <InputText value={companyName} onChange={e => setCompanyName(e.target.value)} required/>
            </div>
            <div className="field">
              <label htmlFor="orid">Địa chỉ công ty<span className="required">*</span></label>
              <InputText value={address} onChange={e => setAddress(e.target.value)} placeholder="Vui lòng nhập đầy đủ thông tin địa chỉ công ty" required/>
            </div>
            <div className="field">
              <label htmlFor="orid">Tên người mua hàng</label>
              <InputText value={customerName} onChange={e => setCustomerName(e.target.value)}/>
            </div>
            <div className="field">
              <label htmlFor="orid">Số điện thoại</label>
              <InputText type="tel" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} placeholder="Số điện thoại người mua hàng"/>
            </div>
            <div className="field">
              <label htmlFor="orid">Email<span className="required">*</span></label>
              <InputText type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email nhận hóa đơn điện tử" required/>
            </div>

            <Messages ref={msgs}/>

            <div className="field text-center un-fluid">
              <Button label="Gửi yêu cầu" icon="pi pi-check"/>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}