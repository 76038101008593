import {Fragment, useEffect, useRef, useState} from 'react';

import {Messages} from 'primereact/messages';

import axios from 'src/service/axios';

import {InvoiceDetail} from './components/InvoiceDetail';
import {InvoiceRequest} from './components/InvoiceRequest';

function Index() {

  const msgs = useRef(null);

  const [hashString, setHashString] = useState(null);
  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    if (window.location.search) {

      setHashString(null);
      setInvoice(null);
      msgs.current.clear();

      const params = new URLSearchParams(window.location.search);
      if (params.get('code')) {

        setHashString(params.get('code'));

        axios({
          url: 'getInformationOrder',
          method: 'post',
          data: {hashString: params.get('code')}
        }).then(({data}) => {
          if (data?.listDetails?.orderId) {
            setInvoice(data.listDetails);
          } else if (data?.listDetails?.description) {
            msgs.current.show([{sticky: true, severity: 'warn', detail: data?.listDetails?.description, closable: false}]);
          } else {
            msgs.current.show([{sticky: true, severity: 'warn', detail: 'Không tìm thấy thông tin phiếu tính tiền.', closable: false}]);
          }
        }).catch(() => {
          msgs.current.show([{sticky: true, severity: 'error', detail: 'Có lỗi xảy ra, quý khách vui lòng thử lại sau.', closable: false}]);
        });
      }
    }
  }, []);

  return (
    <div className="layout-wrapper layout-overlay">
      <div className="layout-topbar">
        <div className="layout-topbar-logo">
          <img src="/coop-smile/CoopSmile-Logo.webp" alt="Logo" width={150}/>
        </div>
        <div className="layout-topbar-logo-right flex">
          <img src="/coop-smile/cheers.jpeg" alt="Logo" style={{height: '5rem'}}/>
        </div>
      </div>
      <div className="layout-main-container">
        <div className="layout-main">
          {invoice?.orderId && <Fragment>
						<InvoiceDetail invoice={invoice}/>
						<InvoiceRequest hashString={hashString}/>
					</Fragment>}

          <Messages ref={msgs}/>
        </div>
      </div>
    </div>

  );
}

export default Index;
