import {createRoot} from 'react-dom/client';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import './assets/layout/layout.scss';

import './App.scss';

import App from './app';

const root = createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App/>);
